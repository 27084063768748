<div class="stepper">
  <div *ngFor="let step of steps" class="step" [ngClass]="'step-'+step.state" >
    <div>
      <div class="circle">
        <mat-icon *ngIf="step.state===stepStates.DONE">check</mat-icon>
      </div>
    </div>
    <div class="border-container">
    </div>
    <div class="text-container">
      <h6 class="title">{{step.title|translate}}</h6>
      <div class="caption">
        <p>
          {{step.description|translate}}
        </p>
      </div>
    </div>
  </div>
</div>
