import { ApplicationIds } from './application';

export interface IMainMenuItem {
  appId: ApplicationIds;
  translationTitle: string;
  link: string;
}

export interface ISubMenuItem {
  translationTitle: string;
  link: string;
  // TODO add later required authorities
}

export interface IAppConfig {
  appId: ApplicationIds;
  translationTitle: string;
  isCurrentApp: boolean;
  order: number;
}

export class AppActionItem {
  config: IAppConfig;
  clickHandler: Function = () => {};

  constructor(config: IAppConfig) {
    this.config = config;
  }

  onClick() {
    this.clickHandler();
  }
}

export const APP_CONFIGS: IAppConfig[] = [
  {
    appId: ApplicationIds.HPLUS_SERVICE,
    translationTitle: 'APP_MENU.ITEMS.SERVICE',
    isCurrentApp: false,
    order: 1
  },
  {
    appId: ApplicationIds.CUSTOMER_PORTAL,
    translationTitle: 'APP_MENU.ITEMS.CUSTOMER_PORTAL',
    isCurrentApp: false,
    order: 0
  },
  {
    appId: ApplicationIds.LEVEL_2,
    translationTitle: 'APP_MENU.ITEMS.LEVEL_2',
    isCurrentApp: true,
    order: 4
  },
  {
    appId: ApplicationIds.ELECTRICITY_CALCULATION_TOOL,
    translationTitle: 'APP_MENU.ITEMS.ELECTRICITY_CALCULATION_TOOL',
    isCurrentApp: false,
    order: 2
  },
  {
    appId: ApplicationIds.TENDER_TOOL,
    translationTitle: 'APP_MENU.ITEMS.TENDER_TOOL',
    isCurrentApp: false,
    order: 3
  },
  {
    appId: ApplicationIds.HPLUS_DONGLE,
    translationTitle: 'APP_MENU.ITEMS.HPLUS_DONGLE',
    isCurrentApp: false,
    order: 5
  },
  {
    appId: ApplicationIds.REMOTE_ADMIN,
    translationTitle: 'APP_MENU.ITEMS.REMOTE_ADMIN',
    isCurrentApp: false,
    order: 6
  },
  {
    appId: ApplicationIds.REMOTE_DESKTOP,
    translationTitle: 'APP_MENU.ITEMS.REMOTE_DESKTOP',
    isCurrentApp: false,
    order: 7
  },
  {
    appId: ApplicationIds.OMS_TOOL,
    translationTitle: 'APP_MENU.ITEMS.OMS_TOOL',
    isCurrentApp: false,
    order: 8
  }
];

export const ADMIN_CONSOLE_SUBMENU_ITEMS: ISubMenuItem[] = [
  {
    translationTitle: 'SUBMENU.ITEMS.USERS',
    link: '/pages/users'
  },
  {
    translationTitle: 'SUBMENU.ITEMS.GROUPS',
    link: '/pages/groups'
  },
  {
    translationTitle: 'SUBMENU.ITEMS.SETTINGS',
    link: '/pages/account-settings'
  }
];
