import {Component, OnInit} from '@angular/core';
import {environment} from "../../../environments/local/environment";

@Component({
  selector: 'app-no-app-permission',
  templateUrl: './no-app-access.component.html',
  styleUrls: ['./no-app-access.component.scss']
})
export class NoAppAccessComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

  goToMHP() {
    window.open(environment.links.applications.hcp, "_self");
  }
}
