import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {CompanyData, UsagePreferencesRequestData} from "../models/company-data";
import {Observable} from "rxjs";
import {environment} from "../../environments/local/environment";
import {AddressAutoCompleteRequest, AddressSuggestion} from "../models/address";

@Injectable({
  providedIn: 'root'
})
export class CompanyDataMgmtService {

  private onBoardingProgress = -1;
  private isSanctionedCompany: boolean = false;

  constructor(private http: HttpClient) {
  }


  setOnBoardingProgress(number: number) {
    this.onBoardingProgress = number;
  }

  getOnBoardingProgress(): number {
    return this.onBoardingProgress;
  }


  public createCompanyData(newCompanyData: CompanyData): Observable<CompanyData> {
    return this.http.post<CompanyData>(environment.backend.endpoint.host +
      environment.domain.endpoint.paths.prefix +
      environment.domain.endpoint.paths.companydata, newCompanyData);
  }

  public createDomainUsagePreferences(usagePreferencesRequestData: UsagePreferencesRequestData): Observable<CompanyData> {
    return this.http.post<CompanyData>(environment.backend.endpoint.host +
      environment.domain.endpoint.paths.prefix +
      environment.domain.endpoint.paths.companydata +
      environment.domain.endpoint.paths.usage, usagePreferencesRequestData);
  }

  getCompanyData(): Observable<CompanyData> {
    return this.http.get<CompanyData>(
      environment.backend.endpoint.host +
      environment.domain.endpoint.paths.prefix +
      environment.domain.endpoint.paths.companydata
    );
  }

  public getLogoOfCompanyData(): Observable<any> {
    return this.http.get(
      environment.backend.endpoint.host +
      environment.domain.endpoint.paths.prefix +
      environment.domain.endpoint.paths.companydata +
      environment.domain.endpoint.paths.logo, {responseType: 'arraybuffer'});
  }

  updateCompanyData(updatedCompanyData: CompanyData, file?: File): Observable<CompanyData> {
    const formData: FormData = new FormData();
    formData.append('companyData', JSON.stringify(updatedCompanyData));

    if (updatedCompanyData.logoUpdated) {
      formData.append('logo', file);
    }

    return this.http.put<CompanyData>(environment.backend.endpoint.host +
      environment.domain.endpoint.paths.prefix +
      environment.domain.endpoint.paths.companydata,
      formData, {
        headers: {
          serializer: 'multipart'
        }
      });
  }


  public deleteDomain(): Observable<any> {
    return this.http.delete<any>(environment.backend.endpoint.host +
      environment.domain.endpoint.paths.prefix);
  }

  setSanctionedCompany(isSanctionedCompany: boolean) {
    this.isSanctionedCompany = isSanctionedCompany;
  }

  checkIsSanctionedCompany() {
    return this.isSanctionedCompany;
  }

  getAddressSuggestion(addressAutoCompleteRequest: AddressAutoCompleteRequest): Observable<AddressSuggestion[]> {
    return this.http.post<AddressSuggestion[]>(environment.backend.endpoint.host +
      environment.addresses.endpoint.paths.prefix +
      environment.addresses.endpoint.paths.auto_complete, addressAutoCompleteRequest);
  }

}
