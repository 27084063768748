import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {enableProdMode} from "@angular/core";
import {environment} from "./environments/local/environment";

if (environment.production) {
  enableProdMode();
}

if (!environment.loggingEnabled) { //TODO remove when logging service is used
  window.console.log = () => { }
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
