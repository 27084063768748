import { Injectable } from '@angular/core';
import { environment } from '../../environments/local/environment';
import { HttpClient } from '@angular/common/http';
import { ApplicationDTO, ApplicationIds } from '../models/application';
import { BehaviorSubject, finalize, Observable, of, switchMap } from 'rxjs';
import {
  IActiveUser,
  IInviteUserResponseData,
  InviteUserRequestData,
  IUser,
  IUserDetailDTO,
  IUserListDTO,
  UserFilterParameters
} from '../models/userinfo';
import { PagedResponse } from '../models/pagedresponse';
import { SharedDetailObject } from '../models/shared-detail-object';
import { ERROR_CODES } from '../models/codes';
import { TranslateService } from '@ngx-translate/core';
import { L1L3AuthService, UserInfo } from 'ngx-auth-util-lib';

const ROLE_ADMIN_ID = '243e7851-06d0-42c8-b149-05e4c7bc3c65';

@Injectable({
  providedIn: 'root'
})
export class UserMgmtService {
  private sharedUser = new SharedDetailObject<IUserDetailDTO>();
  private sharedApplicationList = new BehaviorSubject<ApplicationDTO[] | null>(null);

  private _userInfoChanged: BehaviorSubject<UserInfo> = new BehaviorSubject<UserInfo>(null);
  private sharedUserCount = new BehaviorSubject<number>(null);
  private sharedApplicationsRequesting: boolean = false;
  private sharedProfile = new BehaviorSubject<IActiveUser>(null);

  public readonly appDescriptionMap: Record<ApplicationIds, { title: string; description: string }> = {
    [ApplicationIds.HPLUS_SERVICE]: {
      title: 'APPS.SERVICE.TITLE',
      description: 'APPS.SERVICE.DESCRIPTION'
    },
    [ApplicationIds.LEVEL_2]: {
      title: 'APPS.LEVEL_2.TITLE',
      description: 'APPS.LEVEL_2.DESCRIPTION'
    },
    [ApplicationIds.CUSTOMER_PORTAL]: {
      title: 'APPS.CUSTOMER_PORTAL.TITLE',
      description: 'APPS.CUSTOMER_PORTAL.DESCRIPTION'
    },
    [ApplicationIds.ELECTRICITY_CALCULATION_TOOL]: {
      title: 'APPS.ELECTRICITY_CALCULATION_TOOL.TITLE',
      description: 'APPS.ELECTRICITY_CALCULATION_TOOL.DESCRIPTION'
    },
    [ApplicationIds.TENDER_TOOL]: {
      title: 'APPS.TENDER_TOOL.TITLE',
      description: 'APPS.TENDER_TOOL.DESCRIPTION'
    },
    [ApplicationIds.HPLUS_DONGLE]: {
      title: 'APPS.HPLUS_DONGLE.TITLE',
      description: 'APPS.HPLUS_DONGLE.DESCRIPTION'
    },
    [ApplicationIds.REMOTE_ADMIN]: {
      title: 'APPS.REMOTE_ADMIN.TITLE',
      description: 'APPS.REMOTE_ADMIN.DESCRIPTION'
    },
    [ApplicationIds.REMOTE_DESKTOP]: {
      title: 'APPS.REMOTE_DESKTOP.TITLE',
      description: 'APPS.REMOTE_DESKTOP.DESCRIPTION'
    },
    [ApplicationIds.OMS_TOOL]: {
      title: 'APPS.OMS_TOOL.TITLE',
      description: 'APPS.OMS_TOOL.DESCRIPTION'
    }
  };

  constructor(
    private http: HttpClient,
    private translateService: TranslateService,
    private authService: L1L3AuthService
  ) {
    authService.isAuthenticatedSubject.subscribe({
      next: isAuthenticated => {
        if (isAuthenticated) {
          const userInfo = this.authService.getUserInfo();
          this.userInfoChanged.next(userInfo);
        }
      }
    });
  }

  get userInfoChanged(): BehaviorSubject<UserInfo> {
    return this._userInfoChanged;
  }

  getApplications(): Observable<ApplicationDTO[]> {
    return this.http
      .get<ApplicationDTO[]>(
        environment.backend.endpoint.host +
          environment.users.endpoint.paths.prefix +
          environment.users.endpoint.paths.applications
      )
      .pipe(
        switchMap(applications => {
          // TODO: will be unified to library in MH-2313.
          if (applications?.length > 0) {
            applications.forEach(app => {
              app.name = this.translateService.instant(this.appDescriptionMap[app.id].title);
              app.description = this.translateService.instant(this.appDescriptionMap[app.id].description);
            });
          }
          return of(applications);
        })
      );
  }

  public getUsersWithPagination(
    userFilterParameters: UserFilterParameters,
    sort?: string,
    sortDirection?: string
  ): Observable<PagedResponse<IUserListDTO>> {
    const params = userFilterParameters.generateRequestParameters();

    if (sort && sortDirection) {
      params['sort'] = this.sortDataAccessor(sort);
      params['direction'] = sortDirection;
    }

    return this.http
      .get<PagedResponse<IUserListDTO>>(environment.backend.endpoint.host + environment.users.endpoint.paths.prefix, {
        params
      })
      .pipe(
        switchMap(response => {
          // TODO: will be unified to library in MH-2313.
          if (response?.data?.length > 0) {
            response.data.forEach(user => {
              if (user.applications.length > 0) {
                user.applications.forEach(app => {
                  app.name = this.translateService.instant(this.appDescriptionMap[app.id].title);
                  app.description = this.translateService.instant(this.appDescriptionMap[app.id].description);
                });
              }
            });
          }
          return of(response);
        })
      );
  }

  public exportUsers(
    userFilterParameters: UserFilterParameters,
    sort?: string,
    sortDirection?: string
  ): Observable<any> {
    const params = userFilterParameters.generateRequestParameters();

    if (sort && sortDirection) {
      params['sort'] = this.sortDataAccessor(sort);
      params['direction'] = sortDirection;
    }

    return this.http.get(
      environment.backend.endpoint.host +
        environment.users.endpoint.paths.prefix +
        environment.users.endpoint.paths.exports +
        environment.users.endpoint.paths.csv,
      {
        params,
        responseType: 'arraybuffer'
      }
    );
  }

  public getUserById(id: string): Observable<IUser> {
    return this.http
      .get<IUser>(environment.backend.endpoint.host + environment.users.endpoint.paths.prefix + `/${id}`)
      .pipe(
        switchMap(user => {
          // TODO: will be unified to library in MH-2313.
          if (user?.applications?.length > 0) {
            user.applications.forEach(app => {
              app.name = this.translateService.instant(this.appDescriptionMap[app.id].title);
              app.description = this.translateService.instant(this.appDescriptionMap[app.id].description);
            });
          }
          return of(user);
        })
      );
  }

  public deleteUser(id: string): Observable<any> {
    return this.http.delete<any>(
      environment.backend.endpoint.host + environment.users.endpoint.paths.prefix + `/${id}`
    );
  }

  public getOwnUser(): Observable<IActiveUser> {
    return this.http.get<IActiveUser>(
      environment.backend.endpoint.host +
        environment.users.endpoint.paths.prefix +
        environment.users.endpoint.paths.profile
    );
  }

  // public getRoles(): Observable<IRole[]> {
  //   return this.http.get<IRole[]>(
  //     environment.backend.endpoint.host +
  //     environment.users.endpoint.paths.prefix +
  //     environment.users.endpoint.paths.roles);
  // }

  public updateUser(userId: string, roleIds: string[], userGroupIds: string[]): Observable<any> {
    return this.http.put<any>(
      environment.backend.endpoint.host + environment.users.endpoint.paths.prefix + `/${userId}`,
      { roleIds, userGroupIds }
    );
  }

  public inviteUser(inviteUserRequestData: InviteUserRequestData): Observable<IInviteUserResponseData[]> {
    return this.http.post<IInviteUserResponseData[]>(
      environment.backend.endpoint.host +
        environment.users.endpoint.paths.prefix +
        environment.users.endpoint.paths.invitations,
      inviteUserRequestData
    );
  }

  public resendUserInvitation(id: string): Observable<any> {
    return this.http.post<any>(
      environment.backend.endpoint.host +
        environment.users.endpoint.paths.prefix +
        environment.users.endpoint.paths.invitations +
        `/${id}` +
        environment.users.endpoint.paths.resend,
      {}
    );
  }

  getSharedUser(userId: string): Observable<IUser | null> {
    if (this.sharedUser.getId() !== userId) {
      this.updateSharedUser(userId);
    }
    return this.sharedUser.getSubject().asObservable();
  }

  updateSharedUser(userId: string): void {
    this.sharedUser.setId(userId);
    this.getUserById(userId).subscribe({
      next: user => {
        this.sharedUser.updateSubject(user);
      },
      error: err => {
        this.sharedUser.reset();
        this.sharedUser.error(err);
      }
    });
  }

  resetSharedUser(): void {
    this.sharedUser.reset();
  }

  getSharedProfile(observableOnly: boolean = false) {
    if (this.sharedProfile.value === null && !observableOnly) {
      this.updateProfile();
    }
    return this.sharedProfile.asObservable();
  }

  resetSharedProfile(): void {
    this.sharedProfile.next(null);
  }

  updateProfile() {
    this.getOwnUser().subscribe({
      next: user => {
        this.sharedProfile.next(user);
      },
      error: err => {
        this.sharedProfile.next(null);
        this.sharedProfile.error(err);
      }
    });
  }

  updateUserCount(users: number): void {
    this.sharedUserCount.next(users);
  }

  getUserCount(): BehaviorSubject<number> {
    return this.sharedUserCount;
  }

  resetUserCount(): void {
    this.sharedUserCount.next(null);
  }

  getSharedApplicationList(): Observable<ApplicationDTO[] | null> {
    if (!this.sharedApplicationsRequesting && !this.sharedApplicationList?.getValue()) {
      this.updateSharedApplicationList();
    }
    return this.sharedApplicationList.asObservable();
  }

  updateSharedApplicationList(): void {
    this.sharedApplicationsRequesting = true;
    this.getApplications()
      .pipe(
        finalize(() => (this.sharedApplicationsRequesting = false)),
        switchMap(applications => {
          // TODO: will be unified to library in MH-2313.
          if (applications?.length > 0) {
            applications.forEach(app => {
              app.name = this.translateService.instant(this.appDescriptionMap[app.id].title);
              app.description = this.translateService.instant(this.appDescriptionMap[app.id].description);
            });
          }
          return of(applications);
        })
      )
      .subscribe({
        next: applications => {
          this.sharedApplicationList.next(applications);
        },
        error: err => {
          this.sharedApplicationList.next(null);
          this.sharedApplicationList.error(err);
        }
      });
  }

  resetSharedApplicationList(): void {
    this.sharedApplicationList.next(null);
  }

  generateEmailValidationErrorMessage(errorCode: number, isSingularText: boolean): string {
    let message = '';
    switch (errorCode) {
      case ERROR_CODES.USERS_INV_USER_ALREADY_EXIST_IN_SYSTEM:
        if (isSingularText) {
          message = this.translateService.instant('USERS.ERRORS.USERS_INV_USER_ALREADY_EXIST_IN_SYSTEM_SINGULAR');
        } else {
          message = this.translateService.instant('USERS.ERRORS.USERS_INV_USER_ALREADY_EXIST_IN_SYSTEM_PLURAL');
        }
        break;
      case ERROR_CODES.USERS_INV_INVITATION_PENDING:
        if (isSingularText) {
          message = this.translateService.instant('USERS.ERRORS.USERS_INV_INVITATION_PENDING_SINGULAR');
        } else {
          message = this.translateService.instant('USERS.ERRORS.USERS_INV_INVITATION_PENDING_PLURAL');
        }
        break;
      default:
        if (isSingularText) {
          message = this.translateService.instant('USERS.ERRORS.USERS_INVALID_INVITATION_SINGULAR');
        } else {
          message = this.translateService.instant('USERS.ERRORS.USERS_INVALID_INVITATION_PLURAL');
        }
        break;
    }
    return message;
  }

  getRoleTranslationKeyById(roleId: string): string {
    switch (roleId) {
      case ROLE_ADMIN_ID:
        return 'USERS.ROLES.ADMINISTRATOR';
      default:
        return '';
    }
  }

  private sortDataAccessor(property: string): string {
    switch (property) {
      case 'fullName':
        return 'firstName, lastName';
      case 'userName':
        return 'username';
      default:
        return property;
    }
  }
}
