import {AfterContentChecked, ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit} from '@angular/core';
import {AccountMenuItem, BasicAccountMenuItems, DefaultMenuItem} from "ngx-heka-design-lib";
import {ADMIN_CONSOLE_SUBMENU_ITEMS, APP_CONFIGS, AppActionItem, ISubMenuItem} from "../../models/appmenu";
import {environment} from "../../../environments/local/environment";
import {ApplicationDTO, ApplicationIds} from "../../models/application";
import {HttpClient} from "@angular/common/http";
import {LoadingService} from "../../services/loading/loading.service";
import {NotificationService} from "../../services/error-handling/notification.service";
import {Overlay} from "@angular/cdk/overlay";
import {UserMgmtService} from "../../services/user-mgmt.service";
import {TranslateService} from "@ngx-translate/core";
import {L1L3AuthService} from "ngx-auth-util-lib";
import {Subscription} from "rxjs";
import {MatTabNavPanel} from "@angular/material/tabs";
import {ApplicationService} from "../../services/application.service";
import {UtilService} from "../../services/util.service";

@Component({
  selector: 'ngx-l2-menu',
  templateUrl: './l2-menu.component.html',
  styleUrls: ['./l2-menu.component.scss']
})
export class L2MenuComponent implements OnInit, AfterContentChecked, OnDestroy {
  private loading = false;
  private loadingSubscription: Subscription;
  @HostBinding('class.z-index-2000') zIndex2000: boolean = false;
  @Input() tabPanel: MatTabNavPanel;

  menuItems: DefaultMenuItem[] = [{
    route: '/pages',
    cssClass: '',
    title: this.translateService.instant('MAIN_MENU.ADMIN_CONSOLE'),
    hidden: false
  }];

  appMenuItems: AppActionItem[] = [];
  accountMenu: AccountMenuItem[] = [];

  env = environment;
  applications!: ApplicationDTO[];
  appIds = ApplicationIds;
  submenu: ISubMenuItem[] = ADMIN_CONSOLE_SUBMENU_ITEMS;

  constructor(private http: HttpClient,
              private loadingService: LoadingService,
              private notificationService: NotificationService,
              private overlay: Overlay,
              private userMgmtService: UserMgmtService,
              private translateService: TranslateService,
              private applicationService: ApplicationService,
              private utilService: UtilService,
              private cdref: ChangeDetectorRef,
              public authService: L1L3AuthService) {
  }

  ngOnInit(): void {
    this.initLoadingSubscription();
    this.setupAccountMenu();
    this.getProfile();
  }

  ngAfterContentChecked() {
    //needed because of matTabLinks internal ExpressionChangedAfterHasBeenChecked error with ariaLabel
    this.cdref.detectChanges();
  }

  ngOnDestroy(): void {
    this.loadingSubscription?.unsubscribe();
    this.loading = false;
  }

  private setupAccountMenu() {
    const newAccountMenu = BasicAccountMenuItems;
    newAccountMenu[0].clickHandler = () => this.authService.goToProfile();
    newAccountMenu[1].clickHandler = () => this.utilService.goToLink(environment.links.applications.mh2 + environment.imprint.endpoint.paths.imprint);
    newAccountMenu[2].clickHandler = () => this.authService.logout();
    this.accountMenu = [newAccountMenu[0], newAccountMenu[1], newAccountMenu[2]];
  }

  private setupApplicationsMenu() {
    this.appMenuItems = [];
    const unsortedMenuItems: AppActionItem[] = [];
    this.applications?.forEach(application => {
      const foundAppConfig = APP_CONFIGS.find(i => i.appId === application.id);
      if (foundAppConfig) {
        let appMenuItem = new AppActionItem(foundAppConfig);
        this.applicationService.setAppActionItemClickAction(appMenuItem);
        unsortedMenuItems.push(appMenuItem);
      }
    });
    this.appMenuItems = unsortedMenuItems.sort((a, b) => a.config.order - b.config.order)
    if (this.appMenuItems.findIndex(item => item.config.isCurrentApp) < 0) {
      this.authService.logout();
    }
  }

  private getProfile() {
    this.loadingService.setLoading(true);
    this.userMgmtService.getSharedProfile()
      .subscribe({
        next: profile => {
          if (profile) {
            if (profile.applications?.length > 0) {
              this.applications = profile.applications;
            } else {
              this.applications = [];
            }
            this.setupApplicationsMenu();
          }
          this.loadingService.setLoading(false)
        },
        error: err => {
          this.loadingService.setLoading(false);
          throw err;
        }
      })
  }

  private initLoadingSubscription() {
    this.loadingSubscription = this.loadingService.getLoading().asObservable().subscribe({
      next: value => {
        if (value && this.loading) {
          return;
        }
        if (value && !this.loading) {
          this.zIndex2000 = true;
        } else if (!value) {
          this.zIndex2000 = false;
        }
        this.loading = value;
      }
    });
  }
}
