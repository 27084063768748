import { Component } from '@angular/core';
import {ComponentsModule} from "../components.module";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-sanctioned-company',
  standalone: true,
  imports: [
    ComponentsModule,
    TranslateModule
  ],
  templateUrl: './sanctioned-company.component.html',
  styleUrl: './sanctioned-company.component.scss'
})
export class SanctionedCompanyComponent {

}
