<ngx-heka-menu-bar [defaultMenuItems]="menuItems" [accountMenuItems]="accountMenu" [helpLink]="env.links.help"
                   [userEmail]="authService.getUserInfo()?.email">
  <img src="assets/images/hekatron-icons/dashboard.png" routerLink="'/pages'" menu-img>
  <button class="ms" id="sel-admin-menu-btn" mat-icon-button [matMenuTriggerFor]="appMenu"
          [disabled]="appMenuItems.length === 0" app-menu>
    <mat-icon>dashboard</mat-icon>
  </button>
</ngx-heka-menu-bar>

<mat-menu #appMenu="matMenu" class="app-menu">
  <ng-container *ngIf="appMenuItems.length > 0">
    <ng-container *ngFor="let item of appMenuItems">
      <button *ngIf="item.config.appId===appIds.CUSTOMER_PORTAL; else normalAppItem" mat-menu-item
              class="sel-admin-menu app-menu-item border-bottom" [class.active]="item.config.isCurrentApp" (click)="item.onClick()">
        <mat-icon>dashboard</mat-icon>
        <span>{{item.config.translationTitle|translate}}</span>
      </button>
      <ng-template #normalAppItem>
        <button mat-menu-item class="sel-admin-menu app-menu-item"  [class.active]="item.config.isCurrentApp" (click)="item.onClick()">
          <mat-icon>apps</mat-icon>
          <span>{{item.config.translationTitle|translate}}</span>
        </button>
      </ng-template>
    </ng-container>
  </ng-container>
</mat-menu>



<!--<mat-menu #appMenu="matMenu" class="apps-menu">-->
<!--  <ng-container *ngIf="appMenuItems?.length > 0">-->
<!--    <div class="apps-menu-title mb-2" mat-menu-item disabled><b>{{'APP_MENU.TITLE' | translate}}</b></div>-->
<!--    <button *ngFor="let item of appMenuItems" mat-menu-item (click)="goToLink(item.link)"-->
<!--            class="sel-admin-menu mb-3">-->
<!--      <div class="apps-menu-item-wrapper">-->
<!--        <img *ngIf="item?.imgSrc" class="me-4" [src]="item.imgSrc">-->
<!--        <div class="item-desc">-->
<!--          <p class="item-title m-0">{{item.translationTitle | translate}}</p>-->
<!--          <span>{{item.translationContent | translate}}</span>-->
<!--        </div>-->
<!--      </div>-->
<!--    </button>-->
<!--  </ng-container>-->
<!--  <ng-container *ngIf="adminAppsMenuItems?.length > 0">-->
<!--    <div class="border-top-item"></div>-->
<!--    <button *ngFor="let adminItem of adminAppsMenuItems" mat-menu-item (click)="goToLink(adminItem.link)"-->
<!--            class="sel-admin-menu">-->
<!--      <div class="apps-menu-item-wrapper">{{adminItem.translationTitle | translate}}-->
<!--      </div>-->
<!--    </button>-->
<!--  </ng-container>-->
<!--</mat-menu>-->
<div class="container-fluid p-0">
  <div class="sub-menu-container">
    <div class="offset-lg-1 col-lg-10">
      <nav *ngIf="submenu?.length > 0" id="onboarding-highlights-sub-menu" class="sub-menu ngx-heka-tabs" [tabPanel]="tabPanel" mat-tab-nav-bar mat-stretch-tabs="false">
        <a *ngFor="let item of submenu" mat-tab-link routerLinkActive="true" [routerLink]="item.link"
           class="sel-sub-menu"
           #rla="routerLinkActive" [active]="rla.isActive">
          <span>{{item.translationTitle | translate}}</span>
        </a>
      </nav>
    </div>
  </div>
</div>
