import {Injectable} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {NavLink} from "../models/navlink";
import {Router, UrlSegment} from "@angular/router";
import {ApplicationDTO} from "../models/application";
import {IGroupListDTO} from "../models/groups";
import {Breadcrumb} from "ngx-heka-design-lib";
import {TranslateService} from "@ngx-translate/core";
import {RegexType} from "../models/regex-type";
import {environment} from "../../environments/local/environment";

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  constructor(private sanitizer: DomSanitizer,
              private translateService: TranslateService,
              private router: Router) {
  }

  public createImageUrl(logo: ArrayBuffer, mimeType: string): SafeUrl {  //TODO add to shared lib (maybe as pipe)
    if (!(logo instanceof ArrayBuffer)) {
      return null;
    }
    let mimeTypeForUrl = '';
    if (!mimeType.includes('image/')) {
      mimeTypeForUrl = 'image/';
    }
    if (mimeType) {
      mimeTypeForUrl = mimeTypeForUrl + mimeType;
    } else {
      mimeTypeForUrl = mimeTypeForUrl + 'png';
    }

    if (mimeTypeForUrl === 'image/svg') {
      mimeTypeForUrl = mimeTypeForUrl + '+xml';
    }

    const unsafeImageUrl = URL.createObjectURL(new Blob([logo], {type: mimeTypeForUrl}));
    return this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
  }

  calculateCurrentNavLinkIndex(navLinks: NavLink[], urlSegments: UrlSegment[]) {
    if (urlSegments?.length) {
      const slicedUrl = urlSegments[urlSegments.length - 1];
      let currentIndex = 0;
      if (slicedUrl && navLinks?.length > 0) {
        const currentLink = navLinks
          .find(link => link.link.indexOf(slicedUrl?.path) > -1);
        if (currentLink?.index) {
          currentIndex = currentLink.index;
        }
      }
      return currentIndex;
    }
    return 0;
  }

  public compareSelectablesById(a: any, b: any): boolean {
    return a?.id && b?.id && a?.id === b?.id;
  }

  calculateAssignedApplicationsByGroup(groups: IGroupListDTO[]): ApplicationDTO[] {
    let assignedApps: ApplicationDTO[] = [];
    for (let group of groups) {
      for (let app of group.assignedApplications) {
        if (assignedApps.findIndex(assignedApp => assignedApp.id === app.id) < 0) {
          assignedApps.push(app);
        }
      }
    }
    return assignedApps;
  }


  goToLink(link: string) {
    window.open(link, '_blank', 'noopener,noreferrer');
  }

  addFirstBreadcrumbs(breadcrumbs: Breadcrumb[]) {
    if (!breadcrumbs) {
      breadcrumbs = [];
    }
    breadcrumbs.push({label: this.translateService.instant('GENERAL.MEIN_H_PLUS'), url: '/pages'});
  }

  getRegex(regexType: RegexType): string | RegExp {
    switch (regexType) {
      case RegexType.EMAIL_VALIDATOR:
        return /^(.+)@(.+)\.(.+)$/;
      case RegexType.HEKATRON_PHONE_NUMBER_VALIDATION:
        return '^([0]{2}|[+])[1-9]{2,4}[0-9]{7,12}';
      case RegexType.NUMBERS_ONLY:
        return "^[0-9]*$";
      default:
        return null;
    }
  }
  openAppUrlInNewTab(relativePath: string) {
    const link = environment.redirect.host + relativePath;
    this.goToLink(link)
  }

  goToDetails(relativePath: string, newTab?: boolean){
    if(newTab) {
      this.openAppUrlInNewTab(relativePath);
    }else{
      this.router.navigateByUrl(relativePath);
    }
  }
  isNS_ERROR(e: Error) {
    return e.name === 'NS_ERROR_FAILURE';
  }
}
