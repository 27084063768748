import {BehaviorSubject} from 'rxjs';

export class SharedDetailObject<T> {

  private id: string;
  private subject: BehaviorSubject<T>;

  constructor() {
    this.id = null;
    this.subject = new BehaviorSubject<T>(null);
  }

  getId(): string {
    return this.id;
  }

  setId(value: string): void {
    this.id = value;
  }

  getSubject(): BehaviorSubject<T> {
    return this.subject;
  }

  updateSubject(value: T): void {
    this.subject.next(value);
  }

  error(err: any): void {
    this.subject.error(err);
    this.subject = new BehaviorSubject<T>(null); // TODO check
  }

  reset(): void {
    this.id = null;
    this.updateSubject(null);
  }

  // renewSubject() {
  //   this.subject. = new BehaviorSubject<T>(null);
  // }
}
