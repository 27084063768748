import {Component, Input} from '@angular/core';
import {ApplicationDTO, ApplicationSelection} from "../../models/application";

@Component({
  selector: 'ngx-l2-assigned-application-list',
  templateUrl: './assigned-application-list.component.html',
  styleUrls: ['./assigned-application-list.component.scss']
})
export class AssignedApplicationListComponent {
  @Input() assignedApplications: ApplicationDTO[] = [];

}
