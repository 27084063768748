import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OauthComponent} from './oauth/oauth.component';
import {TranslateModule} from "@ngx-translate/core";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {GlobalLoadingComponent} from "./loading/global-loading/global-loading.component";
import {StepperComponent} from './stepper/stepper.component';
import {L2MenuComponent} from './l2-menu/l2-menu.component';
import {NgxHekaDesignLibModule} from "ngx-heka-design-lib";
import {MatMenuModule} from "@angular/material/menu";
import {MatTabsModule} from "@angular/material/tabs";
import {RouterModule} from "@angular/router";
import {
  L2ChangeTableColumnsButtonComponent
} from "./l2-change-table-columns-button/l2-change-table-columns-button.component";
import {AssignedApplicationListComponent} from "./assigned-application-list/assigned-application-list.component";
import { ApplicationListForTableComponent } from './application-list-for-table/application-list-for-table.component';
import {NoAppAccessComponent} from "./no-app-permission/no-app-access.component";
import {MatFormFieldModule} from "@angular/material/form-field";
import {L2MinimalMenuBarComponent} from "./l2-minimal-menu-bar/l2-minimal-menu-bar.component";
import { ApplicationImgComponent } from './application-img/application-img.component';


@NgModule({
  declarations: [
    OauthComponent,
    GlobalLoadingComponent,
    StepperComponent,
    L2MenuComponent,
    L2ChangeTableColumnsButtonComponent,
    AssignedApplicationListComponent,
    ApplicationListForTableComponent,
    NoAppAccessComponent,
    L2MinimalMenuBarComponent,
    ApplicationImgComponent
  ],
  exports: [
    StepperComponent,
    L2MenuComponent,
    L2ChangeTableColumnsButtonComponent,
    AssignedApplicationListComponent,
    ApplicationListForTableComponent,
    L2MinimalMenuBarComponent,
    ApplicationImgComponent
  ],
    imports: [
        CommonModule,
        TranslateModule,
        MatProgressSpinnerModule,
        MatButtonModule,
        MatIconModule,
        MatSnackBarModule,
        NgxHekaDesignLibModule,
        MatMenuModule,
        MatTabsModule,
        RouterModule,
        MatFormFieldModule
    ]
})
export class ComponentsModule {
}
