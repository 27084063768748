<div class="d-flex flex-row align-items-center" *ngIf="apps?.length > 0; else noApps" >
  <div class="app-image me-4">
    <ngx-l2-application-img [applicationId]="apps[0].id"></ngx-l2-application-img>
  </div>
  <div>
    <span>{{apps[0].name}}</span>
    <br>
    <span *ngIf="apps.length === 2"
          class="secondary-color">+ 1 {{'GROUPS.SINGLE_MORE_APPS' | translate}}</span>
    <span *ngIf="apps.length > 2"
          class="secondary-color">+ {{apps.length - 1}} {{'GROUPS.MULTIPLE_MORE_APPS' | translate}}</span>
  </div>
</div>
<ng-template #noApps>-</ng-template>
