import {Component, Input, OnInit} from '@angular/core';
import {StepperStep, StepperStepState} from "ngx-heka-design-lib";

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit {
  @Input() steps: StepperStep[] = [];
  stepStates = StepperStepState;

  ngOnInit(): void {
  }

}
