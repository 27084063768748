import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'ngx-l2-change-table-columns-button',
  templateUrl: './l2-change-table-columns-button.component.html',
  styleUrls: ['./l2-change-table-columns-button.component.scss']
})
export class L2ChangeTableColumnsButtonComponent implements OnInit {
  @Input() displayedColumns: string[];
  @Input() possibleColumns: string[];
  @Input() columnOverlayRef: OverlayRef;
  @Input() fixedColumns: string[];
  @Input() category: string;

  @ViewChild('columnsButton') columnsButton;
  columnTranslationMap: { [key: string]: string } = {};


  constructor(private overlay: Overlay, private translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.columnTranslationMap = this.createColumnTranslationMap();
  }

  private createColumnTranslationMap(): { [key: string]: string } {
    let obj = {};
    this.possibleColumns
      .forEach(column => {
        obj[column] = this.translateService.instant(this.category.toUpperCase() + '.TABLE_HEADERS.' + column.toUpperCase());
      });
    return obj;
  }
}
