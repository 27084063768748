export const ENVIRONMENT_GLOBAL = {
  envName: 'global',
  loggingEnabled: true,
  pageSize: 30,
  searchSelectPageSize: 10,
  debounceTime: 750,
  debounceTimeSearchSelect: 200,
  inputErrorSnackbarDuration: 3000,
  dialogs: {
    large: {
      width: '80vw',
      maxWidth: '1104px',
      minWidth: '350px'
    }
  },
  appVersion: require('../../package.json').version,
  redirect: {
    route: '/oauth'
  },
  auth_L1: {
    endpoint: {
      paths: {
        account: '/account',
        login: '/protocol/openid-connect/auth',
        token: '/protocol/openid-connect/token',
        certs: '/protocol/openid-connect/certs',
        logout: '/protocol/openid-connect/logout',
        register: '/protocol/openid-connect/registrations'
      }
    }
  },
  backend: {
    endpoint: {
      paths: {
        prefix: '/api',
        token: '/auth/token',
        logout: '/auth/logout',
      }
    }
  },
  files: {
    paths: {
      prefix: '/docs',
      filePath: {}
    }
  },
  imprint: {
    endpoint: {
      paths: {
        imprint: '/impressum'
      }
    }
  },
  links: {
    data_security: 'https://www.hekatron-brandschutz.de/datenschutz',
    help: 'https://www.meinhplus.de/lernen/faq-haeufig-gestellte-fragen',
    marketing: 'https://www.hekatron-brandschutz.de/kontakt',
    homepage: 'https://www.meinhplus.de/',
    av: 'https://www.meinhplus.de/fileadmin/Bilder/MeinHplus/Impressum/Vereinbarung-Auftragsverarbeitung-AVV_Hekatron_HVG_HPlus-Office_Service-App.pdf',
    email: 'info@hekatron.de',
    applications: {
      elec_calc_tool: 'https://www.hekatron-planungstools.de/stromberechnung.php',
      tender_tool_extend: 'https://www.hekatron-planungstools.de/Extend.php',
      tender_tool_light: 'https://www.hekatron-planungstools.de/light.php',
      dongle: 'https://platform.meinhplus.de/#/licenses/dongle-management',
      remote_admin: 'https://remote.meinhplus.de/admin',
      remote_desktop: 'https://remote.meinhplus.de/auth/'
    }
  },
  domain: {
    endpoint: {
      paths: {
        prefix: '/api/domain',
        companydata: '/companydata',
        usage: "/usage",
        logo: "/logo"
      }
    }
  },
  users: {
    endpoint: {
      paths: {
        prefix: '/api/users',
        applications: '/applications',
        overview: "/overview",
        exports: "/exports",
        profile: "/profile",
        invitations: "/invitations",
        csv: "/csv",
        resend: "/resend",
        onboardingCompleted: '/onboarding-completed'
      }
    }
  },
  usergroups: {
    endpoint: {
      paths: {
        prefix: '/api/usergroups',
        users: "/users"
      }
    }
  },
  addresses: {
    endpoint: {
      paths: {
        prefix: '/api/addresses',
        auto_complete: '/auto-complete'
      }
    }
  },

};
