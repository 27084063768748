import {Component, Input} from '@angular/core';
import {ApplicationDTO} from "../../models/application";

@Component({
  selector: 'ngx-l2-application-list-for-table',
  templateUrl: './application-list-for-table.component.html',
  styleUrls: ['./application-list-for-table.component.scss']
})
export class ApplicationListForTableComponent {
  @Input() apps: ApplicationDTO[];
}
