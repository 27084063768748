import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Observable} from 'rxjs';
import {CompanyDataMgmtService} from "../services/company-data-mgmt.service";

@Injectable({
  providedIn: 'root'
})
export class OnboardingGuard  {

  constructor(private companyDataService: CompanyDataMgmtService, private router: Router) {
  }


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.companyDataService.getOnBoardingProgress() > -1) {
      return true;
    } else {
      this.router.navigate(['']);
    }
    return false;

  }

}
